.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
iframe{max-width:100%;border:none;width:100%;height:100%;}
input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active,textarea:-webkit-autofill,textarea:-webkit-autofill:hover,textarea:-webkit-autofill:focus,textarea:-webkit-autofill:active,select:-webkit-autofill,select:-webkit-autofill:hover,select:-webkit-autofill:focus,select:-webkit-autofill:active{background:none;-webkit-box-shadow:0 0 0px 1000px #ffffff inset;transition:background-color 5000s ease-in-out 0s;outline:none;}
input:hover,input:focus,input:active,textarea:hover,textarea:focus,textarea:active,select:hover,select:focus,select:active{background:none;		/*-webkit-box-shadow: 0 0 0px 1000px #ffffff inset;*/transition:background-color 5000s ease-in-out 0s;outline:none;}

/**************************** Coman Css *****************************/
h2{font-weight:bold;font-size:30px;line-height:45px;color:#000000;}

/* Button Css */
.btn{font-size:16px;font-weight:normal;font-stretch:normal;font-style:normal;letter-spacing:normal;text-align:center;line-height:46px;border:1px solid;border-radius:4px;padding:0 30px;display:inline-block;outline:none;}
.arrow_btn{font-size:16px;font-weight:normal;font-stretch:normal;font-style:normal;letter-spacing:normal;text-align:center;color:#ffffff;line-height:46px;border:1px solid #f16a54;border-radius:4px;background-color:#f16a54;padding:0 30px;display:inline-block;}
.btn-check:focus+.btn-primary,.btn-primary:focus{outline:none;box-shadow:unset;}
.btn-primary{border-color:#f16a54;background-color:#f16a54;}
.arrow_btn:hover,.btn-primary:hover,.btn-check:focus+.btn-primary,.btn-primary:focus{color:#f16a54;background:transparent;border-color:#f16a54;}
.arrow_btn:hover svg path{fill:#f16a54;}
.close_icon {
  transition: all ease 0.3s 0s;
  position: absolute;
  right: -22px;
  top: -20px;
  background: #b4b4b4;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 13px;
  justify-content: center;
}
/**************************** Coman Css *****************************/

#meetArea{
  position: fixed;
  height: 100%;
  width: 100%;
}

.show {
  display: block;
}

.hide {
  display: none;
}
#loader  {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: #0000006b;
  z-index: 999999999999999999;
}

#loader.hide {
  display: none;
}
.login_row_box_text {
  color: rgb(235, 57, 57);
  position: relative;
  left: 11px;
}