/******************* informattion **********************/

.informattion h4 { list-style: none; font-style: normal; font-weight: normal; font-size: 1.4em; line-height: 1.3; color: #000000; padding-bottom: 0.8em; }
.informattion p {font-style: normal;font-weight: normal;font-size: 1em;padding: 0 0 15px 0;line-height: 1.4;color: #666666;}
.informattion span{font-weight: normal;font-size: 1em;line-height: 1.3;text-transform: capitalize;color: #172D3D;display: block;}
.informattion strong{font-style: normal;font-weight: normal;font-size: 1em;line-height: 1.4;color: #666666;}
.informattion {max-width: 35em;}
.team_box {
    padding-top: 1em;
}
p,h1,h2,h3,h4,h5,h6,ul,li,a{margin:0;padding:0;line-height:1}
.login_main { display: flex; height: 100vh; font-size: 0.835vw; }
/******************* informattion **********************/
/* thank you page */
.thank_you_box {
    height: calc(90% - 35px);
    display: flex;
    align-items: center;
}