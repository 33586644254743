@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
iframe{max-width:100%;border:none;width:100%;height:100%;}
input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active,textarea:-webkit-autofill,textarea:-webkit-autofill:hover,textarea:-webkit-autofill:focus,textarea:-webkit-autofill:active,select:-webkit-autofill,select:-webkit-autofill:hover,select:-webkit-autofill:focus,select:-webkit-autofill:active{background:none;-webkit-box-shadow:0 0 0px 1000px #ffffff inset;-webkit-transition:background-color 5000s ease-in-out 0s;transition:background-color 5000s ease-in-out 0s;outline:none;}
input:hover,input:focus,input:active,textarea:hover,textarea:focus,textarea:active,select:hover,select:focus,select:active{background:none;		/*-webkit-box-shadow: 0 0 0px 1000px #ffffff inset;*/transition:background-color 5000s ease-in-out 0s;outline:none;}

/**************************** Coman Css *****************************/
h2{font-weight:bold;font-size:30px;line-height:45px;color:#000000;}

/* Button Css */
.btn{font-size:16px;font-weight:normal;font-stretch:normal;font-style:normal;letter-spacing:normal;text-align:center;line-height:46px;border:1px solid;border-radius:4px;padding:0 30px;display:inline-block;outline:none;}
.arrow_btn{font-size:16px;font-weight:normal;font-stretch:normal;font-style:normal;letter-spacing:normal;text-align:center;color:#ffffff;line-height:46px;border:1px solid #f16a54;border-radius:4px;background-color:#f16a54;padding:0 30px;display:inline-block;}
.btn-check:focus+.btn-primary,.btn-primary:focus{outline:none;box-shadow:unset;}
.btn-primary{border-color:#f16a54;background-color:#f16a54;}
.arrow_btn:hover,.btn-primary:hover,.btn-check:focus+.btn-primary,.btn-primary:focus{color:#f16a54;background:transparent;border-color:#f16a54;}
.arrow_btn:hover svg path{fill:#f16a54;}
.close_icon {
  transition: all ease 0.3s 0s;
  position: absolute;
  right: -22px;
  top: -20px;
  background: #b4b4b4;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 13px;
  justify-content: center;
}
/**************************** Coman Css *****************************/

#meetArea{
  position: fixed;
  height: 100%;
  width: 100%;
}

.show {
  display: block;
}

.hide {
  display: none;
}
#loader  {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: #0000006b;
  z-index: 999999999999999999;
}

#loader.hide {
  display: none;
}
.login_row_box_text {
  color: rgb(235, 57, 57);
  position: relative;
  left: 11px;
}


/********************* Header *********************/
header{padding:20px 0;background:#FFFFFF;box-shadow:0px 0px 15px rgb(0 0 0 / 4%);}
.wrapper{max-width:1300px;margin:0 auto;padding:0 50px;}
.header_inr{display:flex;align-items:center;justify-content:space-between;}
.header_logo{max-width:100px;}
.header_right{display:flex;align-items:center;justify-content:flex-end;position:relative;}
.header_menu li{display:inline-block;padding:0 10px;}
.header_menu li a{display:flex;align-items:center;padding:0;margin:0;font-style:normal;font-weight:600;font-size:16px;line-height:1;color:#000000;}
.header_menu li a span{min-width:35px;margin-right:3px;}
.header_menu li a:hover{color:#f16a54;}
.header_menu li a:hover svg path{fill:#f16a54;}

/* Profile dropdown */
.header_profile{display:flex;align-items:center;}
.header_profile_name p{font-style:normal;font-weight:500;font-size:14px;line-height:21px;color:#000000;}
.header_profile_pic img{width:40px;height:40px;border-radius:100%;margin-right:10px;}
.header_profile_link{display:flex;margin-left:10px;}
.login_box a{display:block;}
.login_box{padding:0;margin:0 0 0 30px;}
.login_box h4{font-family:Poppins;font-style:normal;font-weight:500;font-size:14px;line-height:21px;color:#000000;padding-right:10px;}
.login_box{display:flex;align-items:center;}
.login_box a:hover svg path{fill:#f16a54;opacity:1;}

/********************* Header *********************/

p,h1,h2,h3,h4,h5,h6,ul,li,a{margin:0;padding:0;line-height:1}
a{text-decoration:none;outline:none;color:#000}
*{box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-o-box-sizing:border-box;-ms-box-sizing:border-box}
body{font-family:'Poppins',sans-serif;font-size:13px;color:#333;margin:0;overflow-x:hidden;background:#F9FCFF;}
a:hover{color:#333;text-decoration:none}
img{vertical-align:top;max-width:100%;height:auto;border:none;}
.meeting_icon svg{height: 35px;width: 35px;     margin-right: 12px;}



/******************* profile-popup **********************/
svg.js-edit.pass_hide {display: block !important;}

.input_box {padding-bottom: 10px !important;}
.profile_box_head a{display: inline-block;}
.profile_image_icon {display: inline-block;position: relative;}
.profile_camera_image_icon {
    position: absolute;
    bottom: -7px;
    cursor: pointer;
    display: inline-block;
    right: -10px;
    width: 27px;
    height: 27px;
}
.profile_camera_image_icon input#profile_upload {
    opacity: 0;
    width: 27px;
    height: 27px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.profile_btn {justify-content: flex-start;}
.profile_input label {padding: 0 15px 0 0;margin: 0;font-size: 15px;text-align: right;font-weight: 400;width: 35%;color: #605F6D;}
.profile_input p{font-size: 16px;color: #000000; border: none;background: none;}
.profile_image.input_col_12 { position: relative; width: 20%;}
.profile_image_icon_one img {border-radius: 10px;height: 150px;width: 150px;object-fit: cover;object-position: center;}
.profile_camera_image_icon img {width: auto;height: auto;}
.profile_input_edit {display: flex;align-items: center;justify-content: space-between;width: 70%;position: relative;overflow: hidden;border-radius: 5px;}
.profile_input {display: flex; align-items: center; padding: 6px 0; border-bottom: 1px solid #F5F4F5;}
.profile_input_edit a { position: absolute; right: 0; top: 0; bottom: 0; width: 40px; display: flex; justify-content: center; align-items: center; border-left: 1px solid rgb(155 170 186 / 45%);}
.profile_input_edit a:hover{
    background: #F16A54;
    border-color: #F16A54;
}
.profile_input_edit a:hover svg path {
    fill: #fff;
}
.profile_input_edit p {font-size: 16px;color: #3E3E4C;}
.close_icon svg {max-width: 18px;}
.modal-body{padding: 0;}
.profile_input:last-child{padding-bottom: 0 !important; border-bottom: 0 !important;}
.foot_main .profile_btn{padding: 0;}
.open_remove_password label {padding-bottom: 0;}
.profile_info_side {width: 80%;}
.btn_hide {display: none;}
.profile_input_edit input {box-shadow: 0px 0px 0.9375em rgb(0 0 0 / 4%) !important;height: 3.125em !important;width: 100% !important;border: 0 !important;padding: 0 1.25em !important;background: #e9ecef;border-radius: 2px;}
.profile_input {display: flex;align-items: center;padding: 0 0;border-bottom: 0;}
.is-editing .btn_hide{display: block;}
.profile_edit_btn {display: flex;align-items: center;position: absolute;right: 0;top: 0;bottom: 0;justify-content: center;}
.name_edit_btn {
    height: 100%;
    border: 0;
    width: 43px;
    padding: 0;
    border-left: 1px solid rgb(155 170 186 / 45%);
    background: transparent;
}
.name_edit_btn:hover {
    background: #F16A54;
    border-color: #F16A54;
}
.name_edit_btn:hover svg path {
    fill: #fff;
}
.is-editing .name_edit_btn_box {display: none;}
.name_edit_btn.btn-default {
    padding: 0;
    border-radius: 0;
    border-left: 1px solid rgb(155 170 186 / 45%);
}
.name_edit_btn.btn-default:hover {
    background: #F16A54;
    border-color: #F16A54;
}
.input_box_mane_info {width: 100%;}

.js-save {padding: 0 13px 0px 0;background: none;border: none; box-shadow: none !important;}
.info_new_password input {width: 70%;background: #e9ecef;height: 40px;padding: 0 15px;border: 0;     font-size: 14px;color: #3E3E4C;line-height: 18px; border-radius: 2px;}
.new_password_btn {padding-top: 30px;}  
.add_new_password {display: none;}
.intro .add_new_password {display: block;}
.intro .open_remove_password{display: none;}
.open_remove_password {padding-bottom: 0 !important;}

.profile_image_icon_one img {border-radius: 10px;height: 150px;width: 150px;object-fit: cover;object-position: center;}
.profile_camera_image_icon img {width: auto;height: auto;}
img{vertical-align:top;max-width:100%;height:auto;border:none}
.input_box_mane_info input{font-size:14px;color:#3E3E4C;line-height:18px;}
.modal-header{padding: 0 0 10px 0;border: 0;}
.foot_main {display: flex;flex-wrap: wrap;align-items: flex-start;padding-top: 10px;}
.modal-content{background: #FFFFFF;border-radius: 10px;padding: 30px;}

.show-error{color: rgb(235, 57, 57);}
.add_new_password .show-error {
    position: relative;
    left: 34%;
}

/******************* profile-popup **********************/



/******************* login **********************/

.login_main {
  display: flex;
  height: 100vh;
  font-size: 0.835vw;
}
.login_info_left {
  width: 50%;
  padding: 3.125em;
}
.login_img_right {
  width: 50%;
  text-align: right;
}
.login_img_right img {
  height: 100%;
  width: 100%;
}

.login_logo img {
  width: 8.5em;
}
.nav_tabs {
  display: inline-block;
  transition: all 0.2s ease-in-out 0s;
}
.nav_tabs a {
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.3;
  color: #959799;
  display: inline-block;
  padding: 0 0 1em;
  margin: 0 1.875em 0 0;
}
.nav_tabs.active a {
  color: #000000;
}
.tabs-nav {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
}
.tabs.login_left_main {
  padding: 3.125em 0 0 0;
}

.login_left_main .tabs-nav:after {
  content: "";
  position: absolute;
  height: 0.0625em;
  width: 100%;
  background: #c7cacc;
  bottom: 0;
  left: 0;
}
.login_info_left ul .tab-active {
  border-bottom: 3px solid #f16a54;
}
.login_tabs_row {
  display: none;
}
.login_tabs_row.active {
  display: block;
}
.tabs-nav {
  position: relative;
}
i.active-marker {
  height: 0.1875em;
  background: #f16a54;
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 0.15s linear 0s;
  width: auto;
}

.lofin_title_info {
  padding-top: 2.5em;
  padding-bottom: 0.9375em;
}
.lofin_title_info h3 {
  font-weight: 600;
  font-size: 1.875em;
  line-height: 1.5;
  color: #000000;
}
.lofin_title_info p {
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5;
  color: #000000;
  opacity: 0.5;
}
.login_input label {
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5;
  color: #000000;
  padding-bottom: 0.625em;
  margin: 0;
}
.login_input input {
  box-shadow: 0px 0px 0.9375em rgba(0, 0, 0, 0.04);
  height: 3.125em;
  width: 100%;
  border: 0;
  padding: 0 1.25em;
}
.login_input {
  padding: 0.9375em;
  width: 100%;
}
.forgot_password a {
  font-weight: 600;
  font-size: 1em;
  line-height: 1.5;
  color: #1a2730;
  margin: 0.9375em 0 1.875em 0;
  display: block;
}
.click_here p {
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5;
  color: #000000;
  padding-top: 1.875em;
}
.click_here a {
  color: #f16a54;
}
.register_btn {
  padding-top: 1.875em;
}
.lofin_title_info h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.875em;
  line-height: 1.5em;
  color: #000000;
}
.login_row_box {
  display: flex;
  margin: 0 -0.9375em;
  flex-wrap: wrap;
}
.login_2_column {
  width: 50%;
}

.login_info_left .btn {
  font-size: 1em;
  line-height: 1;
  border: 0.0625em solid;
  border-radius: 0.25em;
  padding: 1em 1.875em;
}
.forgot_password_title {
  padding: 0 0 0.9375em 0;
}
.nav_tabs a {
  color: #959799;
}
.new_password_btn {
  display: flex;
  justify-content: flex-end;
}
/* .new_password_btn .btn {padding: 0 12px;height: 40px;display: flex;align-items: center;justify-content: center;} */
.new_password_btn .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.new_password_btn .btn-primary {
  margin-right: 10px;
}

.ErrorColor{
  color: red;
}
/******************* login **********************/




/******************* Meeting  **********************/
.meeting_section {
  padding: 40px 0;
}
.metting_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.right_meet_section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.meet_calender input {
  font-family: "Poppins", sans-serif !important;
  border: 1px solid #ffffff00;
  width: 100%;
  border-radius: 5px !important;
  padding: 12px 20px;
  background: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.search_box {
  margin: 0 20px;
  position: relative;
}
.search_box input {
  border-radius: 4px;
  border: solid 1px #ffffff;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: normal;
  color: #040406;
  padding: 12px 20px;
  width: 100%;
  font-family: "Poppins", sans-serif !important;
  transition: all ease 0.3s 0s;
}
.search_box input::-webkit-input-placeholder {
  color: #808080;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}
.search_box input:-ms-input-placeholder {
  color: #808080;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}
.search_box input::placeholder {
  color: #808080;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}
.search_box input:focus,
.meet_calender input:focus {
  border-color: #f16a54;
  box-shadow: 0 2px 4px rgb(241 106 84 / 26%);
}
.search_icon {
  position: absolute;
  right: 20px;
  top: 18px;
  padding: 0;
  background: transparent;
  border: 0;
  opacity: 0.2;
  cursor: pointer;
}
input[type="date"i]::-webkit-calendar-picker-indicator {
  width: 14px;
  height: 14px;
  background: url(/static/media/calender_icon.ca8557a7.svg) no-repeat center;
  background-size: 100%;
  padding: 0;
  cursor: pointer;
}

/*************** Profile Page ****************/

.profile_bg_img img{width:100%;}
.date_info_suport_bar{text-align:right;padding-right:20px;}
.orange{color:#F16A54;}
.grey{color:#B3B3B3;}
.join_now{line-height:36px;padding:0px 35px;}
.date_info_main{display:flex;align-items:flex-start;padding-bottom:30px;margin-bottom:30px;border-bottom:1px solid #E5E5E5;}
.date_info_suport_bar h5{font-family:Poppins;font-style:normal;font-weight:normal;font-size:21px;line-height:20px;}
.date_info_suport_bar strong{font-family:Poppins;font-style:normal;font-weight:bold;font-size:30px;line-height:35px;}
.date_info_suport_bar h3{font-family:Poppins;font-style:normal;font-weight:normal;font-size:21px;line-height:25px;}
.date_info_box{width:93%;border-left:1px solid #E5E5E5;}
.date_info_box_left{display:flex;width:100%;align-items:center;padding-bottom:20px; padding-left: 20px;}
.date_info_box_left h6{font-family:Poppins;font-style:normal;font-weight:normal;font-size:16px;line-height:25px;color:#000000;max-width:284px;}
.daily_standup{border-radius:0px 10px 10px 0px;padding:10px;max-width:275px;width:28%;border-left:2px solid;}
.meeting_list_otr{background:#FFFFFF;box-shadow:0px 0px 15px rgb(0 0 0 / 4%);border-radius:15px;padding:30px;}
.foot_main {display: flex;flex-wrap: wrap;align-items: flex-start;padding-top: 10px;}
.profile_bg_img img {
  width: 100%;
}
.date_info_suport_bar {
  text-align: right;
  width: 7%;
  padding-right: 20px;
}
.orange {
  color: #f16a54;
}
.grey {
  color: #b3b3b3;
}
.join_now {
  line-height: 36px;
  padding: 0px 35px;
}
.date_info_main {
  display: flex;
  align-items: flex-start;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
}
.date_info_suport_bar h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 20px;
}
.date_info_suport_bar strong {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
}
.date_info_suport_bar h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 25px;
}
.date_info_box {
  width: 93%;
  border-left: 1px solid #e5e5e5;
}
.date_info_box_left {
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 20px;
  padding-left: 20px;
}
.date_info_box_left h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  max-width: 284px;
}
.daily_standup {
  border-radius: 0px 10px 10px 0px;
  padding: 10px;
  max-width: 275px;
  width: 28%;
  border-left: 2px solid;
}
.meeting_list_otr {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 4%);
  border-radius: 15px;
  padding: 30px;
}
.foot_main {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 10px;
}

/******************** Daily Standup color *********************/

.green {
  background: #ecfbf8;
  border-color: #7ee5d0;
}
.red {
  background: #fdedea;
  border-color: #f38775;
}
.purple {
  background: #eeeaf9;
  border-color: #8d73d7;
}
.yellow {
  background: #fffaeb;
  border-color: #fcdf7c;
}

/******************** Daily Standup color *********************/

.mail_box_main {
  display: flex;
  align-items: center;
  width: 18%;
  justify-content: center;
}
.mail_box_info p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  padding-right: 10px;
}
.number_key a {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #ecfbf8;
  display: block;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 7px;
  line-height: 10px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.time_info {
  opacity: 0.3;
  /* text-align: center; */
  width: 50%;
}
.time_info span {
  display: inline-block;
  padding-left: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  padding-right: 20px;
}
.time_hour_info {
  display: flex;
  align-items: center;
  width: 27%;
  justify-content: flex-start;
}

.hour_info, .time_hour_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hour_info {
  background: #f1f9ff;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}
.hour_info span {
  display: inline-block;
  padding-left: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  opacity: 0.5;
}
.popup_edit_info {
  display: flex;
  align-items: center;
  width: 18%;
  justify-content: center;
}
.edit_box {
  width: 28px;
  height: 28px;
  background: #d1d4d6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.delete_box {
  width: 28px;
  height: 28px;
  background: #ffccd0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.view_all {
  width: 28px;
  height: 28px;
  background: #fce1dd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.meeting_list_otr .date_info_main:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.date_info_box_left:last-child {
  padding-bottom: 0;
}
.date_info_main:last-child {
  margin-bottom: 0;
}
.login_box {
  display: flex;
  align-items: center;
}
.login_box h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  padding-right: 10px;
}
.edit_box,
.delete_box,
.view_all {
  cursor: pointer;
}
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif !important;
  font-size: 13px;
  color: #333;
  margin: 0;
  overflow-x: hidden;
  background: #f9fcff;
}
img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
  border: none;
}
.center_mood {
  text-align: center;
  justify-content: center;
}

/* Not Found Css Meeting */

.meeting_not_found {
  display: block;
  text-align: center;
  width: 100%;
}

.meeting_btn_view_box {
  display: flex;
  justify-content: flex-start;
  min-width: 104px;
  max-width: 104px;
}

/******************* Create Meeting-popup **********************/

.modal-content{background: #FFFFFF;border-radius: 10px;padding: 30px;}
.close_icon {transition: all ease 0.3s 0s;position: absolute;right: -22px;top: -20px;background: #b4b4b4;border-radius: 50%;width: 40px;height: 40px;display: flex;align-items: center;padding: 13px;justify-content: center;}
.modal-header{padding: 0 0 10px 0;border: 0;}
.modal-header h3 {font-style: normal;font-weight: 600;font-size: 26px;line-height: 39px;color: #000000;}
.modal-dialog {max-width: 850px;margin: 1.75rem auto;}
.participants_box label {font-size: 19px;}
.participants_box textarea {flex: 1 1;background: #e9ecef;outline: none;line-height: 1;padding: 10px 15px;border-radius: 2px;width: 100%;resize: none; height: 100px;border: 0;}
.participants_box label {font-size: 16px;font-weight: 500;}
.participants_box input {width: 100%;flex: 1 1;position: relative; box-shadow: 0px 0px 0.9375em rgb(0 0 0 / 4%) !important;height: 3.5em !important;width: 100% !important;border: 0 !important;padding: 0 1.25em !important;background: #e9ecef;border-radius: 5px;}
.participants_box {padding:12px 10px;width: 100%;}
.modal-footer {border: 0;}
/* .modal-body {padding-bottom: 0;} */
.form_2_column {display: flex;flex-wrap: wrap;margin: 0 -10px;}
.input_column_2 {width: 50%;}
.participants_box input {
    width: 100%;
    flex: 1 1;
    position: relative;
    box-shadow: 0px 0px 0.9375em rgb(0 0 0 / 4%) !important;
    height: 3.5em !important;
    width: 100% !important;
    border: 0 !important;
    padding: 0 1.25em !important;
    background: #e9ecef !important;
    border-radius: 5px;
}
/******************* Create Meeting-popup **********************/
/******************** Participants box  *********************/
 .all-mail{border-radius:5px;width:100%;display:flex;flex-wrap:wrap;margin:0 -8px 0;}
.all-mail .email-ids{background:#f6f6f6!important;padding:7px;margin:0 8px 16px;border-radius:5px;display:flex;align-items:center;}
.email-ids .cancel-email{background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADuSURBVHgB7ZVLDoIwEIb/wyAPFxpJcKFXMK65oWzkUOjOxxVwBkucNChtoa76JZMGmGc7U4BAwIE9SUWygB0RSa3sjWGDlqQhWRnabJQ+21WwIBKGV5J0RL9Qeqx/gf2OdAa9gyfJ8otepiW2hiOFcMQZpwMJ3dT3uwo8iUw56gPm6j2fkdy6BDPBFcitKn0F6pGVtCJwDk+UWrAjPPG3yjIRiNeD9hxjJuQc8TrUjdytk5uE5+ohHMY/EuGu3MIR0zmK8ZlDpzPU52os40Qk1sDybqyEoenhy1u/hgU7kjPet78NXNEJlv+zQKDjBbH9W1csN21TAAAAAElFTkSuQmCC) no-repeat right;background-size:cover;max-width:17px;font-size:0;height:15px;width:15px;margin-left:8px!important;cursor:pointer;}
.email-id-row input.enter-mail-id{width:100%;}
.error_msg { padding: 7px 0; color: red; font-size: 14px; line-height: 1.3; }
/******************** Participants box  *********************/


/************* popup css ***************/
.close_icon:hover{-webkit-transform:rotate(180deg);transform:rotate(180deg);}
.modal-header h3{font-size:20px;}
.dropdown-menu{padding:10px;}
.calender_icon span.input-group-text{display:flex!important;padding:0;height:100%;align-items:center;border:0; background: #e9ecef !important; width: 49px; justify-content: center; align-items: center;}
.date_box input,.timepicker input,.participants_box input{flex:1 1;background:#fff;outline:none;border:1px solid #dee2e6;line-height:1;padding:10px 15px;border-radius:5px;}
.date_box{position:relative;}
.calender_icon{position:absolute;padding:0;right:0;top:0;bottom:0;border-radius:0;border-left:1px solid #c1c3c6;cursor:pointer;border-radius:5px;}
.timepicker input{width:100%;position:relative;min-height:42px;}
input[type="time"i]::-webkit-calendar-picker-indicator{position:absolute;right:0;top:0;bottom:0;height:auto;width:48px;padding:0;background:url(/static/media/calender_icon.ca8557a7.svg)no-repeat center;margin:0;cursor:pointer;border-left:1px solid #646464; cursor: pointer;}
label,.form-label{padding:0;display:block;margin:0 0 5px;}
.btn-secondary:hover {background: transparent !important;color: #6c757d !important; border-color: #6c757d;}
.btn-secondary{transition: all ease 0.3s; }

.input_box_mane_info input{font-size:14px;color:#3E3E4C;line-height:18px;}
.time_piker::-webkit-calendar-picker-indicator{background:url(/static/media/time.194d6fc1.svg)no-repeat center!important;background-size:42%!important;opacity:0.3!important;}
.nav_tabs a{color:#959799;}
.nav_tabs.active a{color:#000000;}
.delete_text p{font-size:17px;}
.delete_text{padding:5px 0px 10px 0;text-align: center;}
.modal-header h2{font-size:24px;line-height:34px;}
.delete_popup{max-width:650px;}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker__input-container:after {
  content: "";
  position: absolute;
  padding: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-left: 1px solid #c1c3c6;
  cursor: pointer;
  width: 49px;
  border-radius: 0;
  background: url(/static/media/calender_icon.ca8557a7.svg)no-repeat center;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 95px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}

.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range ,
.react-datepicker__quarter-text--in-selecting-range ,
.react-datepicker__year-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__quarter-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__year-text--in-range {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\00d7";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}


.tags-input {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	min-height: 50px;
	border: 1px solid rgb(214, 216, 218);
	border-radius: 6px;
  width: "1000px";
  border: "1px solid #eee";
  background: #ebecee;
  padding: "25px";
  margin: "20px"
}
	.input {
		flex: 1 1;
		border: none;
		height: 46px;
		font-size: 14px;
    color: #ebecee;
		padding: 4px 0 0 0;
	}


  .tag {
    width: auto;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0a0a0a;
    padding: 0 8px;
    list-style: none;
    margin: 0;
    background: #ebecee;
}

	.tag-close-icon {
		display: block;
		width: 16px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		font-size: 14px;
		margin-left: 8px;
		/* color: #d6d8db; */
		border-radius: 50%;
		background: #fff;
		cursor: pointer;
	}





/******************* view_all **********************/


.view_all_info_popup{max-width:850px;}
.view_daily_info{padding:15px;max-width:314px;width:65%;margin-bottom:15px;}
.view_daily_time_info{width:100%;padding-top:10px;justify-content:flex-start;}
.from_text p{font-size:14px;padding-right:12px;}
.view_all_right{justify-content:inherit;margin-left:auto; border-left: 1px solid #E5E5E5;padding-left: 20px;}
.description_box_view_all label{margin:0;line-height:21px;font-size:15px;font-weight:500;color:#b8b8b8;}
.description_box_view_all textarea{width:100%;border:0;padding-bottom:5px;resize:none!important;border-bottom:1px solid #E5E5E5;margin:0;height:28px!important;}
.description_box_view_all {padding: 25px 0 10px 0;}
.participants_view{display:flex;align-items:center;}
.participants_view_left span{border-radius:100%;height:35px;font-size:21px;font-weight:500;width:35px;display:flex;align-items:center;justify-content:center;cursor:pointer;}
.participants_view_left img{height:35px;width:35px;border-radius: 100%;}
.participants_view_right{padding-left:10px;}
.participants_view_right span{font-style:normal;font-weight:500;font-size:16px;line-height:22px;color:#000000;}
.participants_view_right p{font-size:14px;line-height:21px;color:#252525;}
.date_view_all{display:flex;align-items:center;padding-right:80px;}
.date_view_all_text{display:block;padding-left:14px;}
.date_view_all_text span{font-size:12px;font-weight:500;color:#989696;}
.date_view_all_text p{font-size:16px;font-weight:400;color:#000;}
.view_all_left{display:flex;align-items:center;padding:10px 0;}
.date_view_all svg{opacity:0.5;font-size:23px;}
.description_see_more a{color:#f16a54;font-size:13px;}
.description_box_view_all p{padding-top:4px;font-size:17px;line-height:25px;}
.view_all_title h4{padding:0;padding-bottom:20px;}
.participants_view_main{display:flex;align-items:center;padding:0 0 0 0;}
.participants_view_title h5{margin:0;line-height:23px;font-size:15px;font-weight:500;color:#b8b8b8;}
.participants_info_next{padding-right:30px;}
.participants_view_title{padding-right:30px;}
.view_all_title{padding-bottom:20px;}
.invite_more svg{height:20px;width:20px;}
.invite_more{display:flex;align-items:center;}
.invite_more p{padding-left:10px;font-size:15px;line-height:23px;}
.participants_view_left{padding:2px;border:1px solid #E5E5E5;border-radius:50%;margin-right:8px;}

.participants_1{background: #EBE1FF; color: #5A1EF0;}
.participants_2{background: #D4F8FF; color: #17D4FF;} 
.participants_3{ background: #FFEEE1; color: #FF7321;}
.participants_4{ background: #FFF0C5; color: #F0BB2D;}
.participants_5{ background: #CEF9E7; color: #0FD479;}

.all_grups {display: flex;align-items: flex-start; padding-top: 25px;}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .bs-tooltip-bottom .tooltip-arrow::before { border-bottom-color: #f27965;}
.tooltip-inner { background-color: #f16a54;}    

.add_more {z-index: 9;display: inline-block;position: absolute;bottom: 55px !important;right: 0;background: #f0f0f0;width: 100%;padding: 20px 0;display: flex;align-items: center;justify-content: center;}
.meeting_details_right {position: relative;}
.meeting_details_right a{padding: 0;}
.delete_btn_center {
    justify-content: center;
}


.border_animation {width: 35px;height: 35px;position: relative;display: flex;justify-content: center;align-items: center;padding: 5px; cursor: pointer;}  
.border_animation svg {position: relative;z-index: 1; max-width: 18px;}
.border_css {border: 2px dashed #FF6610;position: absolute;width: 100%;border-radius: 100%;height: 100%;-webkit-animation: spin 5s linear infinite;animation: spin 5s linear infinite;}
@-webkit-keyframes spin { 100% { -webkit-transform: rotateZ(360deg); transform: rotateZ(360deg);}}
@keyframes spin { 100% { -webkit-transform: rotateZ(360deg); transform: rotateZ(360deg);}}
.modal-content{background: #FFFFFF;border-radius: 10px;padding: 30px;}
.modal-dialog {
    position: relative;}
/******************* view_all **********************/


input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
input:hover,input:focus,input:active,textarea:hover,textarea:focus,textarea:active,select:hover,select:focus,select:active{background:none;transition:background-color 5000s ease-in-out 0s;outline:none;}
/******************* informattion **********************/

.informattion h4 { list-style: none; font-style: normal; font-weight: normal; font-size: 1.4em; line-height: 1.3; color: #000000; padding-bottom: 0.8em; }
.informattion p {font-style: normal;font-weight: normal;font-size: 1em;padding: 0 0 15px 0;line-height: 1.4;color: #666666;}
.informattion span{font-weight: normal;font-size: 1em;line-height: 1.3;text-transform: capitalize;color: #172D3D;display: block;}
.informattion strong{font-style: normal;font-weight: normal;font-size: 1em;line-height: 1.4;color: #666666;}
.informattion {max-width: 35em;}
.team_box {
    padding-top: 1em;
}
p,h1,h2,h3,h4,h5,h6,ul,li,a{margin:0;padding:0;line-height:1}
.login_main { display: flex; height: 100vh; font-size: 0.835vw; }
/******************* informattion **********************/
/* thank you page */
.thank_you_box {
    height: calc(90% - 35px);
    display: flex;
    align-items: center;
}
