/******************* login **********************/

.login_main {
  display: flex;
  height: 100vh;
  font-size: 0.835vw;
}
.login_info_left {
  width: 50%;
  padding: 3.125em;
}
.login_img_right {
  width: 50%;
  text-align: right;
}
.login_img_right img {
  height: 100%;
  width: 100%;
}

.login_logo img {
  width: 8.5em;
}
.nav_tabs {
  display: inline-block;
  transition: all 0.2s ease-in-out 0s;
}
.nav_tabs a {
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.3;
  color: #959799;
  display: inline-block;
  padding: 0 0 1em;
  margin: 0 1.875em 0 0;
}
.nav_tabs.active a {
  color: #000000;
}
.tabs-nav {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
}
.tabs.login_left_main {
  padding: 3.125em 0 0 0;
}

.login_left_main .tabs-nav:after {
  content: "";
  position: absolute;
  height: 0.0625em;
  width: 100%;
  background: #c7cacc;
  bottom: 0;
  left: 0;
}
.login_info_left ul .tab-active {
  border-bottom: 3px solid #f16a54;
}
.login_tabs_row {
  display: none;
}
.login_tabs_row.active {
  display: block;
}
.tabs-nav {
  position: relative;
}
i.active-marker {
  height: 0.1875em;
  background: #f16a54;
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transition: all 0.15s linear 0s;
  transition: all 0.15s linear 0s;
  width: auto;
}

.lofin_title_info {
  padding-top: 2.5em;
  padding-bottom: 0.9375em;
}
.lofin_title_info h3 {
  font-weight: 600;
  font-size: 1.875em;
  line-height: 1.5;
  color: #000000;
}
.lofin_title_info p {
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5;
  color: #000000;
  opacity: 0.5;
}
.login_input label {
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5;
  color: #000000;
  padding-bottom: 0.625em;
  margin: 0;
}
.login_input input {
  box-shadow: 0px 0px 0.9375em rgba(0, 0, 0, 0.04);
  height: 3.125em;
  width: 100%;
  border: 0;
  padding: 0 1.25em;
}
.login_input {
  padding: 0.9375em;
  width: 100%;
}
.forgot_password a {
  font-weight: 600;
  font-size: 1em;
  line-height: 1.5;
  color: #1a2730;
  margin: 0.9375em 0 1.875em 0;
  display: block;
}
.click_here p {
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5;
  color: #000000;
  padding-top: 1.875em;
}
.click_here a {
  color: #f16a54;
}
.register_btn {
  padding-top: 1.875em;
}
.lofin_title_info h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.875em;
  line-height: 1.5em;
  color: #000000;
}
.login_row_box {
  display: flex;
  margin: 0 -0.9375em;
  flex-wrap: wrap;
}
.login_2_column {
  width: 50%;
}

.login_info_left .btn {
  font-size: 1em;
  line-height: 1;
  border: 0.0625em solid;
  border-radius: 0.25em;
  padding: 1em 1.875em;
}
.forgot_password_title {
  padding: 0 0 0.9375em 0;
}
.nav_tabs a {
  color: #959799;
}
.new_password_btn {
  display: flex;
  justify-content: flex-end;
}
/* .new_password_btn .btn {padding: 0 12px;height: 40px;display: flex;align-items: center;justify-content: center;} */
.new_password_btn .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.new_password_btn .btn-primary {
  margin-right: 10px;
}

.ErrorColor{
  color: red;
}
/******************* login **********************/
