/********************* Header *********************/
header{padding:20px 0;background:#FFFFFF;box-shadow:0px 0px 15px rgb(0 0 0 / 4%);}
.wrapper{max-width:1300px;margin:0 auto;padding:0 50px;}
.header_inr{display:flex;align-items:center;justify-content:space-between;}
.header_logo{max-width:100px;}
.header_right{display:flex;align-items:center;justify-content:flex-end;position:relative;}
.header_menu li{display:inline-block;padding:0 10px;}
.header_menu li a{display:flex;align-items:center;padding:0;margin:0;font-style:normal;font-weight:600;font-size:16px;line-height:1;color:#000000;}
.header_menu li a span{min-width:35px;margin-right:3px;}
.header_menu li a:hover{color:#f16a54;}
.header_menu li a:hover svg path{fill:#f16a54;}

/* Profile dropdown */
.header_profile{display:flex;align-items:center;}
.header_profile_name p{font-style:normal;font-weight:500;font-size:14px;line-height:21px;color:#000000;}
.header_profile_pic img{width:40px;height:40px;border-radius:100%;margin-right:10px;}
.header_profile_link{display:flex;margin-left:10px;}
.login_box a{display:block;}
.login_box{padding:0;margin:0 0 0 30px;}
.login_box h4{font-family:Poppins;font-style:normal;font-weight:500;font-size:14px;line-height:21px;color:#000000;padding-right:10px;}
.login_box{display:flex;align-items:center;}
.login_box a:hover svg path{fill:#f16a54;opacity:1;}

/********************* Header *********************/

p,h1,h2,h3,h4,h5,h6,ul,li,a{margin:0;padding:0;line-height:1}
a{text-decoration:none;outline:none;color:#000}
*{box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-o-box-sizing:border-box;-ms-box-sizing:border-box}
body{font-family:'Poppins',sans-serif;font-size:13px;color:#333;margin:0;overflow-x:hidden;background:#F9FCFF;}
a:hover{color:#333;text-decoration:none}
img{vertical-align:top;max-width:100%;height:auto;border:none;}
.meeting_icon svg{height: 35px;width: 35px;     margin-right: 12px;}


